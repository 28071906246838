import React from "react"
import Post from "./post"

const Posts = ({posts, title}) => {
    // console.log(posts)
    return (
        <section>
            <h2 className="text-2xl font-light">{title}</h2>
            <article>
                {posts.map(post => {
                    return <Post post={post} key={post.id}/>
                })}
            </article>
        </section>
    )
}

export default Posts
