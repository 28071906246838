import {StaticImage} from "gatsby-plugin-image"
import React, {useRef} from "react"
import {animated, useSpring} from "react-spring"

import Close from "./icons/Close"
import "./ModalStyles.css"

const StartupSeedModal = ({
                              showStartupSeedModal,
                              setShowStartupSeedModal,
                          }) => {
    const modalRef = useRef()
    const animation = useSpring({
        config: {
            duration: 250,
        },
        opacity: showStartupSeedModal ? 1 : 0,
        transform: showStartupSeedModal ? `translateY(0%)` : `translateY(-100%)`,
    })
    const closeModal = e => {
        if (modalRef.current === e.target) {
            setShowStartupSeedModal(false)
        }
    }
    return (
        <>
            {" "}
            {showStartupSeedModal ? (
                <div
                    role="button"
                    tabIndex={0}
                    className="modalBackground "
                    ref={modalRef}
                    onClick={closeModal}
                >
                    <animated.div
                        style={animation}
                        className="modalWrapper overflow-hidden"
                    >
                        <div showStartupSeedModal={showStartupSeedModal}>
                            <div className="bg-blue-500 py-3 text-center">
                                <h1 className="text-white text-4xl">Startup-Seed Businesses</h1>
                            </div>
                            <div className="flex py-16 h-full">
                                <div className="w-1/4 ml-8 pr-10 border-r-2 border-gray-300 h-full">
                                    <p className="text-justify">
                                        Do you have a minimum viable product and have established
                                        working relationships with partners? If so, you are
                                        investment-ready to solicit support from angel investors and
                                        investment funds to accelerate your business. Partners or
                                        investors in this stage will also prepare for market
                                        validation and scaling.
                                    </p>
                                </div>
                                <div className="w-3/4 pr-10">
                                    <div className="ml-12 flex">
                                        <div className="w-56 mr-3 my-4">
                                            <StaticImage
                                                src="./modal-images/invest-salone.jpeg"
                                                alt="Finance"
                                                className="w-full shadow-lg rounded-md mt-6"
                                            />
                                        </div>
                                        <div className="w-56 m-3">
                                            <StaticImage
                                                src="./modal-images/invest-salone.jpeg"
                                                alt="Finance"
                                                className="w-full shadow-lg rounded-md mt-6"
                                            />
                                        </div>
                                        <div className="w-56 m-3">
                                            <StaticImage
                                                src="./modal-images/invest-salone.jpeg"
                                                alt="Finance"
                                                className="w-full shadow-lg rounded-md mt-6"
                                            />
                                        </div>
                                    </div>
                                    <div className="ml-12 flex">
                                        <div className="w-56 mr-3 my-4">
                                            <StaticImage
                                                src="./modal-images/sledp.jpeg"
                                                alt="Finance"
                                                className="w-full shadow-lg rounded-md mt-6"
                                            />
                                        </div>
                                        <div className="w-56 m-3">
                                            <StaticImage
                                                src="./modal-images/uncdf.jpeg"
                                                alt="Finance"
                                                className="w-full shadow-lg rounded-md mt-6"
                                            />
                                        </div>
                                        <div className="w-56 m-3">
                                            <StaticImage
                                                src="./modal-images/sledp.jpeg"
                                                alt="Finance"
                                                className="w-full shadow-lg rounded-md mt-6"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                className="modalCloseButton text-white"
                                onClick={() => setShowStartupSeedModal(prev => !prev)}
                            >
                                <Close/>
                            </button>
                        </div>
                    </animated.div>
                </div>
            ) : null}{" "}
        </>
    )
}

export default StartupSeedModal
