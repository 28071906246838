import React from "react"
import {Link} from "gatsby"
import {FaAngleRight} from "react-icons/fa"

const Post = ({post}) => {
    console.log(post.frontmatter.slug)
    // const { title } = post.frontmatter.title
    return (
        <div>
            <li className="flex items-baseline mt-4">
                <FaAngleRight className="mr-4 text-blue-500"/>
                <div>
                    <h4>
                        <Link to={`/posts/${post.frontmatter.slug}`}>
                            <h3 className="font-light text-lg hover:underline hover:text-blue-500">
                                {post.frontmatter.title}
                            </h3>
                        </Link>
                    </h4>
                    <p className="uppercase text-xs text-gray-500 mt-1 tracking-wide">
                        {post.frontmatter.date}
                    </p>
                </div>
            </li>
        </div>
    )
}

export default Post
