import {StaticImage} from "gatsby-plugin-image"
import React, {useRef} from "react"
import {animated, useSpring} from "react-spring"

import Close from "./icons/Close"
import "./ModalStyles.css"

const ScaleUpModal = ({showScaleupSeedModal, setShowScaleupSeedModal}) => {
    const modalRef = useRef()
    const animation = useSpring({
        config: {
            duration: 250,
        },
        opacity: showScaleupSeedModal ? 1 : 0,
        transform: showScaleupSeedModal ? `translateY(0%)` : `translateY(-100%)`,
    })
    const closeModal = e => {
        if (modalRef.current === e.target) {
            setShowScaleupSeedModal(false)
        }
    }
    return (
        <>
            {" "}
            {showScaleupSeedModal ? (
                <div
                    role="button"
                    tabIndex={0}
                    className="modalBackground "
                    ref={modalRef}
                    onClick={closeModal}
                >
                    <animated.div
                        style={animation}
                        className="modalWrapper overflow-hidden"
                    >
                        <div showScaleupSeedModal={showScaleupSeedModal}>
                            <div className="bg-blue-500 py-3 text-center">
                                <h1 className="text-white text-4xl">Scaleup Businesses</h1>
                            </div>
                            <div className="flex py-16 h-full">
                                <div className="w-1/4 ml-8 pr-10 border-r-2 border-gray-300 h-full">
                                    <p className="text-justify">
                                        Your Product or service is measurable and shows clear
                                        indications of market growth and is now attracting funding.
                                        Find partners to help scale-up your business now!
                                    </p>
                                </div>
                                <div className="w-3/4 pr-10">
                                    <div className="ml-12 flex">
                                        <div className="w-56 mr-3 my-4">
                                            <StaticImage
                                                src="./modal-images/invest-salone.jpeg"
                                                alt="Finance"
                                                className="w-full shadow-lg rounded-md mt-6"
                                            />
                                        </div>
                                        <div className="w-56 m-3">
                                            <StaticImage
                                                src="./modal-images/invest-salone.jpeg"
                                                alt="Finance"
                                                className="w-full shadow-lg rounded-md mt-6"
                                            />
                                        </div>
                                        <div className="w-56 m-3">
                                            <StaticImage
                                                src="./modal-images/invest-salone.jpeg"
                                                alt="Finance"
                                                className="w-full shadow-lg rounded-md mt-6"
                                            />
                                        </div>
                                    </div>
                                    <div className="ml-12 flex">
                                        <div className="w-56 mr-3 my-4">
                                            <StaticImage
                                                src="./modal-images/sledp.jpeg"
                                                alt="Finance"
                                                className="w-full shadow-lg rounded-md mt-6"
                                            />
                                        </div>
                                        <div className="w-56 m-3">
                                            <StaticImage
                                                src="./modal-images/uncdf.jpeg"
                                                alt="Finance"
                                                className="w-full shadow-lg rounded-md mt-6"
                                            />
                                        </div>
                                        <div className="w-56 m-3">
                                            <StaticImage
                                                src="./modal-images/sledp.jpeg"
                                                alt="Finance"
                                                className="w-full shadow-lg rounded-md mt-6"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button
                                className="modalCloseButton text-white"
                                onClick={() => setShowScaleupSeedModal(prev => !prev)}
                            >
                                <Close/>
                            </button>
                        </div>
                    </animated.div>
                </div>
            ) : null}{" "}
        </>
    )
}

export default ScaleUpModal
