import React, {useState} from "react"
import {graphql, Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Posts from "../components/post/posts"

import "../styles/startup-hero.css"
import PreStartUpModal from "../components/modals/PreStartUpModal"
import StartupSeedModal from "../components/modals/StartupSeedModal"
import ScaleUpModal from "../components/modals/ScaleUpModal"

const IndexPage = ({data}) => {
    const [showModal, setShowModal] = useState(false)
    const [showStartupSeedModal, setShowStartupSeedModal] = useState(false)
    const [showScaleupSeedModal, setShowScaleupSeedModal] = useState(false)

    const {
        allMdx: {nodes: posts},
    } = data
    // console.log(data)

    // Modal Functions
    const openModal = () => {
        setShowModal(prev => !prev)
    }

    const openStartupSeedModal = () => {
        setShowStartupSeedModal(prev => !prev)
    }

    const openScaleupSeedModal = () => {
        setShowScaleupSeedModal(prev => !prev)
    }

    // End of Modal functions

    return (
        <Layout>
            {/* HERO SECTION */}
            <div className="pt-28 md:pt-36 startup-hero w-full">
                <div className="container mx-auto px-2 sm:px-6 md:px-10 lg:px-24">
                    <h1 className="text-center text-gray-100 text-3xl sm:text-4xl md:text-5xl xl:text-6xl text-shadow">
                        <span className="font-extralight">The</span>{" "}
                        <span className="font-semibold">
              Sierra Leone Digital Entrepreneurship Ecosystem
            </span>
                    </h1>
                    <div className="flex justify-center lg:px-24">
                        <div className="w-3/3 px-6 sm:px-6  xl:px-36 mt-6 leading-6">
                            <p className="justify-center text-center text-white">
                                Our system holds over{" "}
                                <span className="font-bold">150+ records</span> of{" "}
                                <span className="font-bold">businesses</span> ranging from Tech
                                Consultancies, FinTech, Agriculture, Telecommunications,
                                Transportation & Logistics, Health & Wellness, Academia and
                                Software Development sectors and many others in Sierra Leone.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Completed rresponsiveness */}

            {/* START-P LEVELS */}
            <div className="bg-white approach-modal shadow-xl rounded-md w-11/12 border-t-4 border-blue-500">
                <h2 className="text-center text-blue-500 py-6 text-3xl md:text-4xl px-24">
                    Startup Levels
                </h2>
                <hr/>
                <div className="grid grid-cols-1 md:grid-cols-3">
                    {/* Level 1 */}
                    <div className="p-8 text-center">
                        <button
                            type="button"
                            className="text-lg md:text-2xl text-blue-500 focus:outline-none"
                            onClick={openModal}
                        >
                            Pre-startup/Pre-seed Stage
                        </button>
                        <p className="mt-2">
                            Do you have a scalable idea, prototype, service with a clear
                            concept and direction on how to get there? Find partners here!
                        </p>
                        <PreStartUpModal
                            showModal={showModal}
                            setShowModal={setShowModal}
                        />
                    </div>

                    {/* Level 2 */}
                    <div className="p-8 text-center">
                        <button
                            type="button"
                            onClick={openStartupSeedModal}
                            className="text-lg md:text-2xl text-blue-500 focus:outline-none items-center"
                        >
                            Startup/Seed Stage
                        </button>
                        <p className="mt-2">
                            Do you have a minimum viable product and have established working
                            relationships with partners?
                        </p>
                        <StartupSeedModal
                            showStartupSeedModal={showStartupSeedModal}
                            setShowStartupSeedModal={setShowStartupSeedModal}
                        />
                    </div>

                    {/* Level 3 */}
                    <div className="p-8 text-center">
                        <button
                            type="button"
                            className="text-lg md:text-2xl text-blue-500 focus:outline-none"
                            onClick={openScaleupSeedModal}
                        >
                            Scale up/Growth Stage
                        </button>
                        <p className="mt-2">
                            Your Product or service is measurable and shows clear indications
                            of market growth and is now attracting funding.
                        </p>
                        {/* <button type="button" onClick={openScaleupSeedModal}>
              Show Modal
            </button> */}
                        <ScaleUpModal
                            showScaleupSeedModal={showScaleupSeedModal}
                            setShowScaleupSeedModal={setShowScaleupSeedModal}
                        />
                    </div>
                </div>
            </div>
            {/* Completed rresponsiveness */}

            {/* BENEFITS OF THE ENT. ECOSYSTEM */}
            <div className="container mx-auto mt-6">
                <div className="mt-4 flex justify-center pt-10">
                    <div className="mt-8 md:w-3/4 lg:w-2/4 mx-12 md:mx-1">
                        <h2 className="text-2xl md:text-4xl font-semibold leading-tight text-center">
                            Benefits of The Sierra Leone Digital Entrepreneurship Ecosystem
                        </h2>
                        <div className="mt-10">
                            <ul>
                                <li className="mt-6 border-l-4 pl-5 border-blue-300 pr-5">
                                    <p className="text-justify">
                                        A one stop data shop for data on startups, investments
                                        opportunities, access to funding , challenges and policy
                                        work in the entrepreneurship ecosystem in Sierra Leone.
                                    </p>
                                </li>
                                <li className="mt-6 border-l-4 pl-5 border-blue-300 pr-5">
                                    <p className="text-justify">
                                        Gain insight on the entrepreneurship landscape by having
                                        access to comprehensive and real-time reports on emerging
                                        startups, key sectoral players, and the overall progress
                                        being made in the ecosystem.
                                    </p>
                                </li>
                                <li className="mt-6 border-l-4 pl-5 border-blue-300 pr-5 text-justify">
                                    <p className="text-justify">
                                        Sierra Leone has a nascent entrepreneurship ecosystem eager
                                        to support ambitious individuals, startups and businesses.
                                        The platform is a partnership arena with potential for
                                        strategic alliances in Sierra Leone, who provide a wide
                                        range of services for entrepreneurs.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Completed rresponsiveness */}

                {/*Running Business */}
                <div className="mt-12 flex justify-center pb-16">
                    <div className="mx-8 md:w-3/3 lg:w-2/3 grid grid-cols-1 md:grid-cols-2 justify-start items-center">
                        <div>
                            <h3 className="text-blue-500">Are you running a business?</h3>
                            <p className="mt-4 text-justify">
                                I run a business/start up! If you run a business/startup or have
                                an idea that can be transformed into a business, we have
                                organisations that are looking for you! Young, creative and
                                curious innovators who are looking for support to develop
                                prototypes or minimum viable products.
                            </p>
                            <div className="mt-8">
                                <Link
                                    className="bg-blue-500 py-3 px-8 md:py-4 md:px-12 rounded text-white text-xl"
                                    to="/ecosystem-benefits"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>

                        <div className="mt-10 text-center md:ml-10">
                            <StaticImage
                                // src="../images/landing/business_shop"
                                src="../images/landing/eco-1.jpg"
                                alt="Woman"
                                width="420"
                                className="rounded-md mt-5"
                            />
                        </div>
                    </div>
                </div>
                {/* Completed rresponsiveness */}

                {/* Register with us */}
                <div className="mt-8 flex justify-center pb-24 mx-8">
                    <div className="md:w-3/3 lg:w-2/3 grid grid-cols-1 md:grid-cols-2 items-center">
                        <div>
                            <StaticImage
                                src="../images/landing/how-to-join.jpg"
                                alt="Woman"
                                width="450"
                                className="rounded-md mt-5"
                            />
                        </div>

                        <div className="mt-10 md:mt-0 md:ml-10">
                            <h3 className="text-blue-500">Where can I join?</h3>
                            <p className="mt-4 pr-10">Start your journey with us!</p>

                            <div className="mt-10">
                                <Link
                                    className="bg-blue-500 py-3 px-8 md:py-4 md:px-12 rounded text-white text-xl"
                                    to="https://finder.entrepreneur.gov.sl"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Join Now
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Completed rresponsiveness */}
            </div>
            {/* Completed rresponsiveness */}

            {/* BLOG POSTS */}
            <div className="bg-gray-100">
                <div className="md:px-24 py-24 relative overflow-hidden">
                    <div className="absolute top-0 left-0 mt-1 overflow-hidden">
                        <StaticImage
                            src="../images/landing/shaded-blue.png"
                            alt="Woman"
                            width="300"
                        />
                    </div>
                    <div className="flex justify-center mt-12 md:mt-0">
                        <div className="w-2/3">
                            <h3 className="text-center text-3xl font-semibold text-blue-500 tracking-wide">
                                Blog Posts
                            </h3>
                            {/* Posts */}
                            <ul className="mt-8">
                                <div>
                                    <Posts posts={posts} title="Recently published posts"/>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="mt-5 flex justify-center">
                        <div className="w-2/3 mb-10">
                            <Link
                                to="/blog"
                                className="hover:text-blue-500 hover:underline text-blue-500"
                            >
                                More News ...
                            </Link>
                        </div>
                    </div>
                    <div className="absolute bottom-0 right-0 -mr-2 -mb-2 overflow-hidden">
                        <StaticImage
                            src="../images/landing/shaded-blue.png"
                            alt="Woman"
                            width="300"
                        />
                    </div>
                </div>
            </div>
            {/* Completed rresponsiveness */}
        </Layout>
    )
}

export const query = graphql`
  {
    allMdx(limit: 3, sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        id
        frontmatter {
          title
          author
          category
          readTime
          slug
          date(formatString: "MMMM, Do YYYY")
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        excerpt
      }
    }
  }
`

export default IndexPage
